<template>
<div>
    <b-row>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Contratos y Documentos</span>
                </CCardHeader>
                <CCardBody>
                    <div>
                        <b-row>
                            <b-col md="12">
                                <b-button size="sm" class="mt-2 float-right" variant="dark" @click="agregarDocumentacion()">
                                    <i class="fas fa-plus fa-sm"></i> Nuevo documento y contrato
                                </b-button>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaDocumentacion.filter(el => el.estado == 2)" :fields="campoDocumentacion" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template #table-colgroup="campoDocumentacion">
                                        <col v-for="field in campoDocumentacion.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                    </template>
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(nombre)="param">
                                        <b-form-input @change=" param.item.nombre = $event" :value="param.item.nombre"></b-form-input>
                                    </template>
                                    <template #cell(tipoDocumento)="param">
                                        <b-form-select value-field="idTipo" text-field="descripcion" v-model="param.item.tipoDocumento" :options="comboTipoDocumentacion">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </template>
                                    <template #cell(fechaPublicacion)="param">
                                        <b-form-input type="date" @change=" param.item.fechaPublicacion = $event" :value="param.item.fechaPublicacion"></b-form-input>
                                    </template>
                                    <template #cell(urlArchivo)="param">
                                        <b-input-group>
                                            <b-input-group-prepend v-if="param.item.urlArchivo != ''">
                                                <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(param.item.urlArchivo)">
                                                    <i class="fas fa-download fa-xs"></i>
                                                </b-button>
                                            </b-input-group-prepend>
                                            <b-form-file ref="file" class="text-left" v-model.lazy="param.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="param.item.nombreArchivo ? param.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="param.item.nombreArchivo ? param.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                        </b-input-group>
                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button @click="eliminarDocumentacion(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                            <b-col md="12">
                                <b-button class="float-right" size="sm" variant="success" @click="registrarDocumentacion"><i class="fas fa-save mr-1"></i> Guardar</b-button>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>

    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoDocumentacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                    width: "5%"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                    width: "25%"
                },
                {
                    key: "tipoDocumento",
                    label: "Tipo de información",
                    class: "text-center",
                    width: "25%"
                },
                {
                    key: "urlArchivo",
                    label: "Documento",
                    class: "text-center",
                    width: "25%"
                },

                {
                    key: "fechaPublicacion",
                    label: "Fecha de publicacion",
                    class: "text-center",
                    width: "15%"
                },

                {
                    key: "acciones",
                    label: "",
                    class: "text-center",
                    width: "5%"
                }
            ],

            listaDocumentacion: [],

            acions: [],

            disabled: false,
            comboTipoDocumentacion: [{
                    idTipo: 1,
                    descripcion: 'Formato de entrevista'
                },
                {
                    idTipo: 2,
                    descripcion: 'Cualificación de personal'
                },
                {
                    idTipo: 3,
                    descripcion: 'Cualificación de auditores'
                },
                {
                    idTipo: 4,
                    descripcion: 'Contrato de locación de servicios'
                },
                {
                    idTipo: 5,
                    descripcion: 'Inducción al puesto'
                },
                {
                    idTipo: 6,
                    descripcion: 'Uso de firmas digitales'
                },
                {
                    idTipo: 7,
                    descripcion: 'Ficha de ingreso personal'
                },
                {
                    idTipo: 8,
                    descripcion: 'Compromiso de imparcialidad, confidencialidad, conflictos de intereses y presiones'
                },
                {
                    idTipo: 9,
                    descripcion: 'Derechos y deberes del personal'
                },
                {
                    idTipo: 10,
                    descripcion: 'Declaración jurada sistema previsional de pensión'
                },
                {
                    idTipo: 11,
                    descripcion: 'Estudio de conflictos de interes del personal'
                },
                {
                    idTipo: 12,
                    descripcion: 'Evaluación auditor'
                },
                {
                    idTipo: 13,
                    descripcion: 'Modelo de CV'
                },
            ],
            datosSession: {
                idCliente: null,
            }
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                //this.listaDocumentoEmpresa.documento = this.$refs.file.files[0];
            }
        },
        downloadFile(url) {
            window.open(url)
        },
        agregarDocumentacion() {
            this.listaDocumentacion.push({
                idContrato: 0,
                tipoDocumento: null,
                nombre: '',
                urlArchivo: '',
                nombreArchivo: '',
                fechaPublicacion: null,
                metaArchivo: null,
                estado: 2,
            })
        },
        eliminarDocumentacion(param) {
            let me = this;
            let listaDoc = me.listaDocumentacion.filter(x => x.estado == 2);
            if (listaDoc[param.index].idContrato != 0) {
                listaDoc[param.index].estado = 1;
            } else if (listaDoc[param.index].idContrato == 0) {
                listaDoc[param.index].estado = 0;
                for (let e in me.listaDocumentacion) {
                    if (me.listaDocumentacion[e].estado == 0) {
                        me.listaDocumentacion.splice(e, 1);
                    }
                }
            }
        },

        obtenerContratosDocumentos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/obtener-contratos-documentos", {
                    params: {
                        idAuditoria: me.$route.params.idAuditoria,
                    }
                })
                .then(function (response) {
                    // me.dataCheckListInicial.idPackCheckListInicial = response.data[0][0].idPackCheckListInicial;
                    console.log(response.data)
                    me.listaDocumentacion = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.$router.push({
                        name: 'Check List de Informacion'
                    })
                });
        },
        registrarDocumentacion() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            let idDoc = 0;

            for (const i in me.listaDocumentacion) {
                if (me.listaDocumentacion[i].idContrato == 0 && me.listaDocumentacion[i].metaArchivo != null) {
                    idDoc = idDoc - 1;
                    formData.append("filex" + idDoc, me.listaDocumentacion[i].metaArchivo);
                } else {
                    formData.append("filex" + me.listaDocumentacion[i].idContrato, me.listaDocumentacion[i].metaArchivo);
                }
            }

            formData.append("datosDocumentacion", JSON.stringify(me.listaDocumentacion));
            formData.append("folder", 'auditoria/contratos-documentos');
            formData.append("idCliente", me.datosSession.idCliente);

            formData.append("idAuditoria", this.$route.params.idAuditoria);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/registrar-contratos-documentos",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.obtenerContratosDocumentos();
                    me.disabled = false;
                     me.$router.push({
                    name: 'ejecutar auditoria'
                     })
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            if (this.$route.params.idAuditoria) {
                this.obtenerContratosDocumentos();
            }
        }
    }
}
</script>
